import React from 'react';

import { TextInput } from '@meterup/connect-ui';
import { required } from '@meterup/connect-ui/src/validation';
import cx from 'classnames';
import { Field, useField } from 'react-final-form';
import PlacesAutocomplete, { AutocompletePrediction } from 'react-places-autocomplete';

interface SearchProps {
  handleSelect: (address: string, placeID: string) => void;
  handleChange: any;
  address?: string;
}

// transform snake_case to camelCase
const formattedSuggestion = (structured_formatting: any) => ({
  mainText: structured_formatting.main_text,
  secondaryText: structured_formatting.secondary_text,
});

/*
  PlacesAutocompleteWithTypesFilter adds additional filtering of place suggestions based on the `types` associated
  with the result. Our goal is to only show results that are full street addresses. According to the docs, specifying
  `types: ["address"]` seems as if it should be sufficient, but in practice that turns out not to be the case. As a result
  we have to filter out all results that aren't of types premise or street_address.

  This seems to be sufficient to achieve the result we want, but we may have to tweak this list in the future.

  This is done via inheritance because the `PlacesAutocomplete` implementation doesn't accept a prop to perform such
  filtering.

  Reference:
  * [All location types](https://developers.google.com/maps/documentation/geocoding/requests-geocoding#Types)
  * [Valid autocomplete location types](https://developers.google.com/maps/documentation/javascript/supported_types#table3)
 */
class PlacesAutocompleteWithTypesFilter extends PlacesAutocomplete {
  autocompleteCallback = (
    predictions: AutocompletePrediction[],
    status: google.maps.places.PlacesServiceStatus,
  ) => {
    this.setState({ loading: false });
    // @ts-ignore
    if (status !== this.autocompleteOK && this.props.onError) {
      // @ts-ignore
      this.props.onError(status, this.clearSuggestions);
      return;
    }
    const { highlightFirstSuggestion } = this.props;
    this.setState({
      suggestions: predictions
        .map((p, idx) => ({
          // @ts-ignore
          id: p.id,
          description: p.description,
          placeId: p.place_id,
          active: !!(highlightFirstSuggestion && idx === 0),
          index: idx,
          formattedSuggestion: formattedSuggestion(p.structured_formatting),
          matchedSubstrings: p.matched_substrings,
          terms: p.terms,
          types: p.types,
        }))
        .filter(
          (p) =>
            p.types.includes('premise') ||
            p.types.includes('subpremise') ||
            p.types.includes('street_address'),
        ),
    });
  };
}

export default function Search({ handleChange, handleSelect, address = '' }: SearchProps) {
  const onError = (status: string, clearSuggestions: any) => {
    clearSuggestions();
  };

  const { meta } = useField('address', {
    validate: required,
  });

  return (
    <PlacesAutocompleteWithTypesFilter
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
      onError={onError}
      searchOptions={{ types: ['address'], componentRestrictions: { country: ['us'] } }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="relative">
          <Field
            meta={meta}
            icon="location"
            controlSize="large"
            placeholder="Search locations"
            autocomplete="off"
            component={TextInput}
            name="BusinessAddress"
            input={getInputProps({ name: 'BusinessAddress' })}
          />
          {loading ||
            (suggestions.length > 0 && (
              <ul className="absolute z-200 w-full p-1 mt-2 text-sm bg-white border border-gray-100 rounded-md shadow-md">
                {loading && <li key="loading">Loading...</li>}
                {suggestions.length > 0 &&
                  suggestions.map((suggestion) => (
                    <li
                      {...(getSuggestionItemProps(suggestion) as any)}
                      key={suggestion.placeId}
                      className={cx('px-4 py-1 my-1 cursor-pointer', {
                        'bg-brand-50 text-brand-600 rounded-md': suggestion.active,
                        'text-gray': !suggestion.active,
                      })}
                    >
                      {suggestion.description}
                    </li>
                  ))}
              </ul>
            ))}
        </div>
      )}
    </PlacesAutocompleteWithTypesFilter>
  );
}

import { merge } from 'lodash-es';
import React from 'react';

import type { GutterProp, SpacingValue } from '../../common/sizing';
import type { SegmentsOrder } from '../../controls/Segments/Segments';
import type { SpacingScale } from '../../utilities/shared/sizes';
import type { StackSpacing } from '../../utilities/Stack/Stack';
import { Divider } from '../../assets/Divider/Divider';
import { sizing } from '../../common/sizing';
import { Segment, Segments } from '../../controls/Segments/Segments';
import { colors, darkThemeSelector, styled } from '../../stitches.config';
import { spacingCSS } from '../../utilities/shared/Spacing';
import { useLocalStorage } from '../../utilities/useLocalStorage';
import { useViewport } from '../../utilities/useViewport';

const ColumnContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'auto',

  '@notDesktop': {
    gridArea: 'content',
  },

  variants: {
    gutter: {
      all: {
        padding: sizing.squish,
      },
      bottom: {
        paddingBottom: sizing.ends,
      },
      horizontal: {
        padding: sizing.sidesOnly,
      },
      left: {
        paddingLeft: sizing.sides,
      },
      none: {},
      right: {
        paddingRight: sizing.sides,
      },
      top: {
        paddingTop: sizing.ends,
      },
      vertical: {
        padding: sizing.endsOnly,
      },
    },
    selected: {
      true: {
        '@notDesktop': {
          display: 'flex',
        },
      },
      false: {
        '@notDesktop': {
          display: 'none',
        },
      },
    },
    position: {
      start: {
        '@desktop': {
          gridArea: 'start',
          strokeRight: colors.strokeApplicationLight,

          [darkThemeSelector]: {
            strokeRight: colors.strokeApplicationDark,
          },
        },
      },
      end: {
        '@desktop': {
          gridArea: 'end',
          strokeLeft: colors.strokeApplicationLight,

          [darkThemeSelector]: {
            strokeLeft: colors.strokeApplicationDark,
          },
        },
      },
    },
  },
});

export type ColumnProps = {
  /**
   * Pass in any content as `children`.
   */
  children?: React.ReactNode;
  /**
   * Set the content of the column.
   */
  content?: React.ReactNode;
  /**
   * Set whether there should be a gutter or not around the children.
   */
  gutter?: GutterProp;
  /**
   * Set the spacing between the children of `Column`.
   */
  spacing?: StackSpacing;
  /**
   * Set the position of the column.
   */
  position?: 'start' | 'end';
};

export function Column({
  children,
  content,
  gutter = 'none',
  spacing,
  position,
  ...remaining
}: ColumnProps) {
  return (
    <ColumnContainer gutter={gutter} css={spacingCSS(spacing)} position={position} {...remaining}>
      {content || children}
    </ColumnContainer>
  );
}

const ColumnSegments = styled('div', {
  gridArea: 'segments',
  display: 'flex',
  flexDirection: 'column',
  gap: '$10',
  padding: `$10 ${sizing.sides} 0`,
  overflow: 'hidden',
});

const ColumnsContainer = styled('div', {
  width: '100%',
  height: '100%',
  minHeight: '0',

  '@notDesktop': {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'min-content 1fr',
    gridTemplateAreas: '"segments" "content"',
  },

  '@desktop': {
    display: 'grid',
    gridTemplateAreas: '"segments segments" "start end"',
    gridTemplateRows: 'min-content 1fr',
  },

  variants: {
    preset: {
      'narrow-wide': {},
      'wide-narrow': {},
      'even-split': {},
    },
  },
});

type ColumnSelected = 'start' | 'end';

export type ColumnsProps = {
  /**
   * Pass in any content as `children`.
   */
  children?: React.ReactNode;
  /**
   * Set the selected column.
   */
  columnSelected?: ColumnSelected;
  /**
   * Set the function to set the selected column.
   */
  setColumnSelected?: (column: ColumnSelected) => void;
  /**
   * Set the id of the columns.
   */
  id?: string;
  /**
   * Set the preset of the columns.
   */
  preset: 'narrow-wide' | 'wide-narrow' | 'even-split';
  /**
   * Set the order of the segments.
   */
  segmentsOrder?: SegmentsOrder;
  /**
   * Set the spacing between the columns.
   */
  spacing?: SpacingValue;
  /**
   * Set the start column.
   */
  start?: {
    /**
     * Set the label of the start column.
     */
    label?: React.ReactNode;
    /**
     * Set the width of the start column.
     */
    width?: SpacingScale;
  } & Omit<ColumnProps, 'position'>;
  /**
   * Set the end column.
   */
  end?: {
    /**
     * Set the label of the end column.
     */
    label?: React.ReactNode;
    /**
     * Set the width of the end column.
     */
    width?: SpacingScale;
  } & Omit<ColumnProps, 'position'>;
};

export function Columns({
  children,
  columnSelected = 'end',
  setColumnSelected = () => {},
  start,
  end,
  id,
  preset,
  segmentsOrder = 'last-to-first',
  spacing = 0,
  ...remaining
}: ColumnsProps) {
  const { breakpoint } = useViewport();
  const [innerColumnSelected = columnSelected, setInnerColumnSelected = setColumnSelected] =
    useLocalStorage<ColumnSelected>(`atto.columns.selected.${id}`, 'end');

  const defaultStartWidth = preset === 'narrow-wide' ? '$260' : '1fr';
  const defaultEndWidth = preset === 'wide-narrow' ? '$360' : '1fr';
  const startWidth = start?.width ? `$${start?.width}` : defaultStartWidth;
  const endWidth = end?.width ? `$${end?.width}` : defaultEndWidth;
  const gridTemplateColumns = `${startWidth} ${endWidth}`;

  return (
    <ColumnsContainer
      preset={preset}
      css={merge(spacingCSS(spacing), {
        gridTemplateColumns: breakpoint !== 'desktop' ? '1fr' : gridTemplateColumns,
      })}
      {...remaining}
    >
      {children}
      {start && end && breakpoint !== 'desktop' && (
        <ColumnSegments>
          <Segments order={segmentsOrder}>
            <Segment
              onClick={() => setInnerColumnSelected('start')}
              active={innerColumnSelected === 'start'}
            >
              {start.label}
            </Segment>
            <Segment
              onClick={() => setInnerColumnSelected('end')}
              active={innerColumnSelected === 'end'}
            >
              {end.label}
            </Segment>
          </Segments>
          <Divider direction="row" />
        </ColumnSegments>
      )}
      {start && (
        <ColumnContainer
          selected={innerColumnSelected === 'start'}
          position="start"
          gutter={start.gutter}
          onClick={() => setInnerColumnSelected('start')}
        >
          {start.content}
        </ColumnContainer>
      )}
      {end && (
        <ColumnContainer
          selected={innerColumnSelected === 'end'}
          position="end"
          gutter={end.gutter}
          onClick={() => setInnerColumnSelected('end')}
        >
          {end.content}
        </ColumnContainer>
      )}
    </ColumnsContainer>
  );
}

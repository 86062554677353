/* eslint-disable eqeqeq */
import React from 'react';

import { Badge, Body, Button, FormattedMoney, Icon } from '@meterup/atto';
import { Quote } from '@meterup/connect-api';
import { FormattedMonthlyAmount, FormattedSpeed, ProviderLogo, Tooltip } from '@meterup/connect-ui';
import cx from 'classnames';

import { formatBandwidth } from '../../../utils/formatBandwidth';

interface QuoteBlockProps {
  quote: Quote;
  selected: boolean;
  disabledSelect: boolean;
  onClick: () => void;

  onPinQuote: (sid: string) => void;
  onUnpinQuote: (sid: string) => void;
  loading?: boolean;
}

function QuoteBlock({
  quote,
  selected,
  disabledSelect,
  onClick,
  onPinQuote,
  onUnpinQuote,
  loading = false,
}: QuoteBlockProps) {
  let availabilityForDisplay: string;
  if (quote.availability && quote.availability !== 'unknown') {
    if (quote.availability === 'location_on_net') {
      availabilityForDisplay = 'Onsite';
    } else {
      availabilityForDisplay = 'Nearby';
    }
  }

  const renderDesktopHeader = () => (
    <header className="flex flex-col">
      <div className="flex px-2 space-x-2">
        <span className="mr-1">
          {quote.recommended && (
            <Badge size="small" variant="brand" ends="pill">
              Saved
            </Badge>
          )}
        </span>
        {quote.availability == 'location_on_net' && (
          <Badge size="small" variant="positive" ends="pill">
            {availabilityForDisplay}
          </Badge>
        )}
        {quote.availability == 'location_near_net' && (
          <Badge size="small" variant="neutral" ends="pill">
            {availabilityForDisplay}
          </Badge>
        )}
      </div>
      <div className="flex items-center">
        {quote.recommended && (
          <span
            className="text-brand xl:mr-2 cursor-pointer py-2 px-2 pb-3"
            onClick={() => onUnpinQuote(quote.sid)}
          >
            <Icon icon="bookmark-filled" />
          </span>
        )}
        {!quote.recommended && (
          <span
            className="text-gray-300 xl:mr-2 cursor-pointer py-2 px-4 pb-3"
            onClick={() => onPinQuote(quote.sid)}
          >
            <Icon icon="bookmark" />
          </span>
        )}
        <div className="h-8 w-28 text-2xl flex items-center justify-between">
          {quote.provider && <ProviderLogo name={quote.provider.name} path={quote.provider.path} />}
        </div>
      </div>
    </header>
  );

  const renderMobileHeader = () => (
    <header className="flex justify-between items-center">
      <div className="h-8 w-22 text-lg flex items-center justify-between">
        {quote.provider && <ProviderLogo name={quote.provider.name} path={quote.provider.path} />}
      </div>
      <div className="flex items-center">
        {quote.availability && quote.availability !== 'unknown' && (
          <div className="pr-2">
            {quote.availability === 'location_on_net' && (
              <Badge variant="alternative">{availabilityForDisplay}</Badge>
            )}
            {quote.availability === 'location_near_net' && (
              <Badge variant="neutral">{availabilityForDisplay}</Badge>
            )}
          </div>
        )}
        {quote.recommended && (
          <span onClick={() => onUnpinQuote(quote.sid)}>
            <Badge variant="brand">
              <div className="flex items-center">
                <span className="text-xs">
                  <Icon icon="bookmark" />
                </span>
                <span>Saved</span>
                <span className="text-xs">
                  <Icon icon="cross" />
                </span>
              </div>
            </Badge>
          </span>
        )}
        {!quote.recommended && (
          <Button
            variant="secondary"
            arrangement="leading-icon"
            icon="bookmark"
            size="medium"
            onClick={() => onPinQuote(quote.sid)}
          >
            <div className="flex items-center">
              <span>Save</span>
            </div>
          </Button>
        )}
      </div>
    </header>
  );

  const renderDesktopContent = () => (
    <div className="flex flex-row px-2 items-center">
      <div className="p-2 text-xs text-gray-600 flex-1 text-right mr-2">
        <div className="ml-auto flex items-center justify-end">
          <div className="flex-column justify-end mr-4">
            <div className="text-xs text-gray-700 font-normal mb-2">
              {/* <span className="mr-1">
            <Icon icon="globe" />
          </span> */}
              <span>{quote.connectionType && quote.connectionType.name}</span>
            </div>
            <div className="flex gap-1 justify-end items-center">
              <span className="inline-block mr-2 whitespace-nowrap">
                <Badge variant="neutral" size="small" icon="calendar" arrangement="leading-icon">
                  {quote.contractLengthMonths}-Month
                </Badge>
              </span>
              <span className="inline-block">
                <Badge variant="neutral" size="small" icon="wrench" arrangement="leading-icon">
                  <FormattedMoney amount={quote.installFeeCents / 100} />
                </Badge>
              </span>
            </div>
          </div>
          <div className=" py-2 px-2 flex-column justify-start">
            <div className="text-xs text-gray-700 whitespace-nowrap">
              <span className="text-gray-600 text-center w-5 mr-2 text-left inline-block">
                <Icon icon="arrow-down" />
              </span>
              <FormattedSpeed mbps={formatBandwidth(quote.downloadKbps)} />
              <span> down</span>
            </div>
            <div className="text-xs text-gray-700 mt-2 text-left">
              <span className="text-gray-600 text-center w-5 mr-2 inline-block">
                <Icon icon="arrow-up" />
              </span>
              <FormattedSpeed mbps={formatBandwidth(quote.uploadKbps)} />
              <span> up</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderContentMobile = () => (
    <div>
      <div className="flex justify-between">
        <Body weight="bold">
          <div className="flex items-center">
            <span className="mr-1">
              <Icon icon="arrow-down" size={14} />
            </span>
            <span className="text-xs">
              <FormattedSpeed mbps={formatBandwidth(quote.downloadKbps)} />
            </span>
            <span className="text-xs text-gray-600">&nbsp;down</span>
          </div>
        </Body>
        <div className="text-xl flex items-center font-bold">
          <FormattedMonthlyAmount amount={quote.monthlyFeeCents / 100} />
        </div>
      </div>

      <div className="flex justify-between mt-1">
        <Body weight="bold">
          <div className="flex items-center">
            <span className="mr-1">
              <Icon icon="arrow-up" size={14} />
            </span>
            <span className="text-xs">
              <FormattedSpeed mbps={formatBandwidth(quote.uploadKbps)} />
            </span>
            <span className="text-xs text-gray-600">&nbsp;up</span>
          </div>
        </Body>
        <div className="text-xs text-gray-600 font-normal">
          {quote.installFeeCents === 0 && 'Free install'}
          {quote.installFeeCents != 0 && (
            <span>
              <FormattedMoney amount={quote.installFeeCents / 100} /> Install fee
            </span>
          )}
        </div>
      </div>
    </div>
  );

  const renderButton = () => (
    <Button
      width="100%"
      variant={selected ? 'secondary' : 'primary'}
      size="large"
      disabled={!selected && disabledSelect}
      onClick={onClick}
      loading={loading}
    >
      {selected ? 'Remove Quote' : 'View Details'}
    </Button>
  );

  const renderButtonWrapper = () => {
    if (!selected && disabledSelect) {
      return <Tooltip title="You can only select up to 2 quotes">{renderButton()}</Tooltip>;
    }
    return renderButton();
  };

  const renderFooter = () => (
    <footer className="flex justify-between flex-col items-start px-4">
      <div className="text-lg flex items-center font-bold">
        <FormattedMonthlyAmount amount={quote.monthlyFeeCents / 100} />
      </div>
      <div className="mt-1 w-full">{renderButtonWrapper()}</div>
    </footer>
  );

  const renderFooterMobile = () => (
    <footer className="flex justify-between">
      <div>
        <div className="text-xs text-gray-700 font-normal">
          {/* <span className="mr-2">
                <Icon icon="speed-medium" />
              </span> */}
          <span>{quote.connectionType && quote.connectionType.name}</span>
        </div>
        <div className="text-xs text-gray-600 font-normal mt-1">
          <span className="mr-1">
            <Icon icon="calendar" size={12} />
          </span>
          <span>
            {quote.contractLengthMonths == 1
              ? 'Month-to-month contract'
              : `${quote.contractLengthMonths}-month`}
          </span>
        </div>
      </div>
      <div>{renderButtonWrapper()}</div>
    </footer>
  );

  const desktopQuoteBlockClasses = cx(
    // Padding
    'relative sm:px-4 py-3 hidden xl:flex',

    // Background
    {
      'dark:m-bg-blue-700 m-bg-blue-50': selected,
      'dark:m-bg-gray-700 m-bg-white border-b border-gray-100 hover:bg-brand-50 hover:border-brand-100':
        !selected,
    },

    // Layout
    'flex-rows items-center',
  );

  const mobileQuoteBlockClasses = cx(
    'xl:hidden',

    // Background
    {
      'dark:m-bg-blue-700 m-bg-gray-50 border border-gray-100 rounded-md mx-2 mt-4': selected,
      'dark:m-bg-gray-700 m-bg-white border border-gray-100 rounded-md mx-2 mt-4 hover:bg-brand-50':
        !selected,
    },
  );

  return (
    <div>
      <div className={mobileQuoteBlockClasses}>
        <div className="p-3 border-b border-gray-100">{renderMobileHeader()}</div>
        <div className="p-3 border-b border-gray-100">{renderContentMobile()}</div>
        <div className="p-3">{renderFooterMobile()}</div>
      </div>

      <div className={desktopQuoteBlockClasses}>
        <div className="xl:flex-1">{renderDesktopHeader()}</div>
        <div className="mt-5 xl:mt-0 xl:flex-3">{renderDesktopContent()}</div>
        <div className="mt-3 xl:mt-0 xl:flex-1">{renderFooter()}</div>
      </div>
    </div>
  );
}

export default QuoteBlock;

import type { AccessLevelProp, SizerProp, TableHeadCellTooltipProp } from '@meterup/atto';
import type {
  Cell,
  Column,
  ColumnDef,
  ColumnFiltersState,
  FilterFn,
  FilterFnOption,
  OnChangeFn,
  Row,
  RowData,
  RowSelectionState,
  SortingState,
  Table as ReactTable,
  TableState,
  VisibilityState,
} from '@tanstack/react-table';
import type { Dispatch, ForwardedRef, JSX, SetStateAction } from 'react';
import type { To } from 'react-router-dom';
import {
  HorizontalScrollShadow,
  sizer,
  Table,
  TableBody,
  TableCell,
  TableCellBuffer,
  TableCellState,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableRow,
  useHorizontalShadowOnScroll,
} from '@meterup/atto';
import { rankings, rankItem } from '@tanstack/match-sorter-utils';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import React, {
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { EnhancedReactRouterLink } from './ReactRouterLink';

export type { ColumnDef, Row, SortingState };
export { createColumnHelper, rankings, rankItem };

export type AutoTableAlignmentProp = 'start' | 'center' | 'end';
export type AutoTableStuckProp = 'top' | 'right' | 'bottom' | 'left';
export type AutoTableOverflowProp = 'auto' | 'hidden' | 'scroll' | 'visible';

declare module '@tanstack/table-core' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-shadow
  interface ColumnMeta<TData extends RowData, TValue> {
    isLeading?: boolean;
    alignment?: AutoTableAlignmentProp;
    hasField?: boolean;
    hideSortIcon?: boolean;
    minBreakpoint?: 'sm' | 'md' | 'lg' | 'xl';
    tooltip?: TableHeadCellTooltipProp;
    stuck?: AutoTableStuckProp;
    accessLevel?: AccessLevelProp | undefined | null;
    width?: SizerProp;
    maxWidth?: SizerProp;
    minWidth?: SizerProp;
    overflow?: AutoTableOverflowProp;
  }

  interface FilterFns {
    equalsIfSet: FilterFn<any>;
    arrIncludesIfSet: FilterFn<any>;
  }
}

function calculateGapPenalty(value: string, pattern: string) {
  let lastIndex = -1;
  let gap = 0;
  for (const char of pattern) {
    const index = value.indexOf(char, lastIndex + 1);
    if (index === -1) return Infinity;
    if (lastIndex !== -1) {
      gap += index - lastIndex - 1;
    }
    lastIndex = index;
  }
  return gap;
}

// Based on https://tanstack.com/table/v8/docs/api/features/filters#filter-meta
function defaultGlobalFilterFn<D extends any>(
  row: Row<D>,
  columnId: string,
  filterValue: string,
  addMeta: (meta: any) => void,
) {
  // make dots, dashes, colons, and underscores interchangeable
  const pattern = filterValue.replace(/[.\-_:]/g, '');
  const value = String(row.getValue(columnId)).replace(/[.\-_:]/g, '');

  const itemRank = rankItem(value, pattern);

  const gap = calculateGapPenalty(value, pattern);
  // if more than 2 extra characters (gaps) exist, reject the match.
  if (gap > 2) {
    itemRank.passed = false;
  }

  addMeta(itemRank);

  return itemRank.passed;
}

const getSizeProps = (_: ReactTable<any>, column: Column<any>) => {
  const meta = column.columnDef.meta ?? {};
  const {
    alignment = 'start',
    hasField = false,
    hideSortIcon,
    width,
    maxWidth,
    minWidth,
    overflow,
  } = meta;

  return {
    alignment,
    hasField,
    hideSortIcon,
    style: {
      // eslint-disable-next-line no-nested-ternary
      width: maxWidth ? sizer(maxWidth) : width ? sizer(width) : undefined,
      // eslint-disable-next-line no-nested-ternary
      minWidth: minWidth ? sizer(minWidth) : width ? sizer(width) : undefined,
      overflow,
    },
  };
};

type GetLinkTo<D extends any> = (row: D) => To | null | undefined;
type IsRowDisabled<D> = (row: D) => boolean;
type IsRowSelected<D> = (row: D) => boolean;
type IsRowCollapsed<D> = (row: D) => boolean;
type OnRowDeselect<D> = (row: D) => void;
type OnRowClick<D> = (row: D) => void;

type SubAutoTableProps<D> = {
  data: D;
  isNested: boolean;
};

function AutoTableCellOverflowContents({ children }: { children: React.ReactNode }) {
  const { getBoxShadow, onScrollHandler, handleTargetChange } = useHorizontalShadowOnScroll('both');

  const scrollRef = useRef<HTMLDivElement>(null);

  const handleResizeNavigation = useCallback(() => {
    if (scrollRef.current) {
      handleTargetChange(scrollRef.current);
    }
  }, [handleTargetChange, scrollRef]);

  useEffect(() => {
    handleResizeNavigation();
    window.addEventListener('resize', handleResizeNavigation);
    return () => {
      window.removeEventListener('resize', handleResizeNavigation);
    };
  }, [handleResizeNavigation]);

  return (
    <HorizontalScrollShadow {...getBoxShadow} onScroll={onScrollHandler} ref={scrollRef}>
      {children}
    </HorizontalScrollShadow>
  );
}

function AutoTableCellImpl<D extends any, E extends React.ElementType>({
  as,
  cell,
  table,
  isNavigable,
  isNested,
  to,
  hotkeyTos,
}: {
  as?: E;
  cell: Cell<D, any>;
  table: ReactTable<D>;
  isMultiSelected?: boolean;
  isNavigable?: boolean;
  isNested?: boolean;
  to?: To;
  hotkeyTos?: Record<string, To>;
}) {
  if (cell.column.columnDef.meta?.accessLevel === null) return null;

  const content = flexRender(cell.column.columnDef.cell, cell.getContext());
  const { hideSortIcon, ...sizeProps } = getSizeProps(table, cell.column);

  const meta = cell.column.columnDef.meta ?? {};

  return (
    <TableCell
      key={cell.id}
      {...sizeProps}
      isLeading={meta.isLeading}
      isNavigable={isNavigable}
      isNested={isNested}
      accessLevel={meta.accessLevel}
      stuck={meta.stuck}
      as={meta.isLeading ? as : undefined}
      to={meta.isLeading ? to : undefined}
      // add the prop conditionally like this so react devtools doesn't complain
      {...(meta.isLeading ? { hotkeyTos } : {})}
    >
      {sizeProps.style.overflow === 'auto' || sizeProps.style.overflow === 'scroll' ? (
        <AutoTableCellOverflowContents>{content}</AutoTableCellOverflowContents>
      ) : (
        content
      )}
    </TableCell>
  );
}

const AutoTableCell = memo(AutoTableCellImpl) as typeof AutoTableCellImpl;

interface AutoTableRowProps<D extends any> {
  table: ReactTable<D>;
  row: Row<D>;
  getLinkTo?: GetLinkTo<D>;
  getDrawerLinkTo?: GetLinkTo<D>;
  getEditLinkTo?: GetLinkTo<D>;
  isRowDisabled?: IsRowDisabled<D>;
  isRowSelected?: IsRowSelected<D>;
  isRowCollapsed?: IsRowCollapsed<Row<D>>;
  onToggleRowCollapse: (row: Row<D>) => void;
  onRowClick?: OnRowClick<D>;
  isMultiSelected?: boolean;
  isNested?: boolean;
  renderSubTable?: (props: SubAutoTableProps<D>) => JSX.Element;
  'data-index'?: number;
}

function AutoTableRowImpl<D extends any>(
  {
    table,
    row,
    getLinkTo,
    getDrawerLinkTo,
    getEditLinkTo,
    isRowDisabled,
    isRowSelected,
    isRowCollapsed,
    onRowClick,
    onToggleRowCollapse,
    isMultiSelected,
    isNested,
    renderSubTable,
    'data-index': dataIndex,
    ...restProps
  }: AutoTableRowProps<D>,
  ref?: ForwardedRef<HTMLTableRowElement>,
) {
  const to = getLinkTo?.(row.original);
  const editTo = getEditLinkTo?.(row.original);
  const drawerTo = getDrawerLinkTo?.(row.original);
  const hotkeyTos: Record<string, To> = {};
  if (editTo) {
    hotkeyTos.e = editTo;
  }
  if (drawerTo) {
    hotkeyTos.d = drawerTo;
  }
  const isDisabled = isRowDisabled?.(row.original) ?? false;
  const isSelected = isRowSelected?.(row.original) ?? false;
  const localCollapsed = isRowCollapsed?.(row) ?? false;

  const handleClick = useCallback(() => {
    onRowClick?.(row.original);
  }, [onRowClick, row.original]);

  const subRowData = row.original;
  const subTable = renderSubTable ? renderSubTable({ data: subRowData, isNested: true }) : null;

  return (
    <>
      <TableRow
        ref={ref}
        key={row.id}
        isDisabled={isDisabled}
        isSelected={!!(isSelected || isMultiSelected)}
        isNested={isNested}
        onClick={handleClick}
        data-index={dataIndex}
      >
        <TableCellBuffer side="leading" />
        <TableCellState side="leading" />
        {renderSubTable && (
          <TableCell
            collapsable
            onClick={() => onToggleRowCollapse(row)}
            collapsed={localCollapsed}
          />
        )}
        {row.getVisibleCells().map((cell) => (
          <AutoTableCell
            as={to ? EnhancedReactRouterLink : undefined}
            to={to ?? undefined}
            hotkeyTos={to ? hotkeyTos : undefined}
            key={cell.id}
            table={table}
            cell={cell}
            isMultiSelected={isMultiSelected}
            isNested={isNested}
          />
        ))}
        <TableCellState side="trailing" />
        <TableCellBuffer side="trailing" />
      </TableRow>
      {renderSubTable && !localCollapsed && (
        <TableRow {...restProps}>
          <TableCell hasNested colSpan={1000}>
            {subTable}
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

export const AutoTableRow = React.forwardRef(AutoTableRowImpl) as <D extends any>(
  props: AutoTableRowProps<D> & { ref?: ForwardedRef<HTMLTableRowElement> },
) => ReturnType<typeof AutoTableRowImpl>;

interface AutoTableBodyProps<D extends any> {
  table: ReactTable<D>;
  rows: Row<D>[];
  multiSelectedRows?: RowSelectionState;
  actions?: React.ReactNode;
  getLinkTo?: GetLinkTo<D>;
  getDrawerLinkTo?: GetLinkTo<D>;
  getEditLinkTo?: GetLinkTo<D>;
  isRowDisabled?: IsRowDisabled<D>;
  isRowSelected?: IsRowSelected<D>;
  isRowCollapsed?: IsRowCollapsed<Row<D>>;
  onRowClick?: OnRowClick<D>;
  onToggleRowCollapse: (row: Row<D>) => void;
  isNested?: boolean;
  renderSubTable?: (props: SubAutoTableProps<D>) => JSX.Element;
}

type VirtualAutoTableBodyProps<D extends any> = {
  tableContainerRef: React.RefObject<HTMLDivElement | null>;
} & AutoTableBodyProps<D>;

function VirtualAutoTableBody<D extends any>({
  table,
  isRowDisabled,
  isRowSelected,
  isRowCollapsed,
  onToggleRowCollapse,
  isNested,
  getLinkTo,
  getDrawerLinkTo,
  getEditLinkTo,
  onRowClick,
  multiSelectedRows,
  renderSubTable,
  rows,
  actions,
  tableContainerRef,
}: VirtualAutoTableBodyProps<D>) {
  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 36, // based on a precalculated size
    getScrollElement: () => {
      if (typeof tableContainerRef === 'object') {
        return tableContainerRef?.current ?? null;
      }
      return null;
    },
    overscan: 10,
    measureElement: (element) => element?.getBoundingClientRect().height ?? 36,
  });

  // This is a hack which solves the problem of not having the tableContainerRef.current defined.
  // This tends to happen on first and only render as a consequence of our Suspense setup
  // and will subsequently never be be defined unless there is a state change.
  // Results in an AutoTable with no rows when AutoTable is first rendered.
  // This should only be called exactly once.
  useLayoutEffect(() => {
    rowVirtualizer?.measure?.();
  }, [rowVirtualizer]);

  const virtualRows = rowVirtualizer.getVirtualItems();
  const paddingTop = virtualRows.length > 0 ? virtualRows[0].start : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? rowVirtualizer.getTotalSize() - virtualRows[virtualRows.length - 1].end
      : 0;

  return (
    <TableBody>
      {paddingTop > 0 && (
        <tr aria-hidden>
          <td style={{ height: `${paddingTop}px` }} />
        </tr>
      )}
      {virtualRows.map((virtualRow) => {
        const row = rows[virtualRow.index];
        return (
          <AutoTableRow
            key={row.id}
            data-index={virtualRow.index}
            ref={(node) => rowVirtualizer.measureElement(node)}
            row={row}
            table={table}
            isRowDisabled={isRowDisabled}
            isRowSelected={isRowSelected}
            isRowCollapsed={isRowCollapsed}
            onToggleRowCollapse={onToggleRowCollapse}
            isNested={isNested}
            getLinkTo={getLinkTo}
            getDrawerLinkTo={getDrawerLinkTo}
            getEditLinkTo={getEditLinkTo}
            onRowClick={onRowClick}
            isMultiSelected={multiSelectedRows?.[row.id]}
            renderSubTable={renderSubTable}
          />
        );
      })}
      {actions && (
        <TableRow>
          <TableCellBuffer side="leading" />
          <TableCellState side="leading" />
          <TableCell colSpan={1000}>{actions}</TableCell>
          <TableCellState side="trailing" />
          <TableCellBuffer side="trailing" />
        </TableRow>
      )}
      {paddingBottom > 0 && (
        <tr aria-hidden>
          <td style={{ height: `${paddingBottom}px` }} />
        </tr>
      )}
    </TableBody>
  );
}

function AutoTableBody<D extends any>({
  table,
  isRowDisabled,
  isRowSelected,
  isRowCollapsed,
  isNested,
  getLinkTo,
  getDrawerLinkTo,
  getEditLinkTo,
  onRowClick,
  onToggleRowCollapse,
  multiSelectedRows,
  renderSubTable,
  rows,
  actions,
}: AutoTableBodyProps<D>) {
  return (
    <TableBody>
      {rows.map((row) => (
        <AutoTableRow
          key={row.id}
          row={row}
          table={table}
          isRowDisabled={isRowDisabled}
          isRowSelected={isRowSelected}
          isRowCollapsed={isRowCollapsed}
          onToggleRowCollapse={onToggleRowCollapse}
          isNested={isNested}
          getLinkTo={getLinkTo}
          getDrawerLinkTo={getDrawerLinkTo}
          getEditLinkTo={getEditLinkTo}
          onRowClick={onRowClick}
          isMultiSelected={multiSelectedRows?.[row.id]}
          renderSubTable={renderSubTable}
        />
      ))}
      {actions && (
        <TableRow>
          <TableCellBuffer side="leading" />
          <TableCellState side="leading" />
          <TableCell colSpan={1000}>{actions}</TableCell>
          <TableCellState side="trailing" />
          <TableCellBuffer side="trailing" />
        </TableRow>
      )}
    </TableBody>
  );
}

// react-table throws errors when getting row model if the sort/filter
// state is invalid. This is probably just because of the global URL-based
// search state becoming outdated, so we try resetting the filter and sort
// state and getting the rows again once.
const getRows = <D,>(table: ReactTable<D>) => {
  try {
    return table.getRowModel()?.rows ?? [];
  } catch (err) {
    table.resetSorting();
    table.resetGlobalFilter();
    table.resetColumnFilters();
    return table.getRowModel()?.rows ?? [];
  }
};

type CommonAutoTableProps<D> = {
  actions?: React.ReactNode;
  globalFilter?: string;
  globalFilterFn?: FilterFnOption<D>;
  sortingState?: SortingState;
  onChangeSortingState?: Dispatch<SetStateAction<SortingState>>;
  columnFilters?: ColumnFiltersState;
  onColumnFiltersChange?: OnChangeFn<ColumnFiltersState>;
  data: D[];
  columns: ColumnDef<D, any>[];
  columnVisibility?: VisibilityState;
  getLinkTo?: GetLinkTo<D>;
  getDrawerLinkTo?: GetLinkTo<D>;
  getEditLinkTo?: GetLinkTo<D>;
  isRowDisabled?: IsRowDisabled<D>;
  isRowSelected?: IsRowSelected<D>;
  onRowDeselect?: OnRowDeselect<D>;
  enableSorting?: boolean;
  enableRowSelection?: boolean;
  enableMultiRowSelection?: boolean;
  /**
   * Does not need to end with `.csv`, it will be added automatically.
   */
  exportToCSVFilename?: string;
  onRowClick?: OnRowClick<D>;
  multiSelectedRows?: RowSelectionState;
  onRowMultiSelectionChange?: OnChangeFn<RowSelectionState>;
  getRowId?: (originalRow: D, index: number, parent?: Row<D>) => string;
  size?: 'auto' | 'small' | 'large';
  isNested?: boolean;
  renderSubTable?: (props: SubAutoTableProps<D>) => JSX.Element;
  subTableExpandedByDefault?: boolean;
  onRowsChange?: (rows: Row<D>[]) => void;
  emptyState?: React.ReactNode;
};

type AutoTableProps<D> = CommonAutoTableProps<D> & {
  ref?: ForwardedRef<ExportableMethods<D>>;
} & (
    | { isVirtual?: false; tableContainerRef?: false }
    | {
        isVirtual: true;
        tableContainerRef: React.RefObject<HTMLDivElement | null>;
        renderSubTable?: false;
      }
  ) &
  (
    | {
        rowsOffset: number;
        rowsLimit: number;
      }
    | {
        rowsOffset?: never;
        rowsLimit?: never;
      }
  );

export interface ExportableMethods<D = any> {
  exportToCSV: () => void;
  getFilteredRows: () => Row<D>[];
}

function defaultColumnCanGlobalFilter<D>(c: Column<D>): boolean {
  return c.columnDef.enableGlobalFilter ?? true;
}

const equalsIfSet: FilterFn<any> = (row, columnId: string, filterValue: unknown) =>
  filterValue == null || row.getValue(columnId) === filterValue;

equalsIfSet.autoRemove = (val: any) => !val;

const arrIncludesIfSet: FilterFn<any> = (row, columnId: string, filterValue: unknown[]) =>
  !filterValue?.length || filterValue.includes(row.getValue(columnId));

arrIncludesIfSet.autoRemove = (val: any) => !val;

export const filterFns = {
  equalsIfSet,
  arrIncludesIfSet,
} as const;

export function AutoTable<D extends any>({
  actions,
  sortingState,
  globalFilter,
  globalFilterFn = defaultGlobalFilterFn,
  columnFilters,
  onColumnFiltersChange,
  columns,
  columnVisibility,
  data,
  getLinkTo,
  getDrawerLinkTo,
  getEditLinkTo,
  isRowDisabled,
  isRowSelected,
  onChangeSortingState,
  onRowClick,
  enableSorting = true,
  enableMultiRowSelection = false,
  exportToCSVFilename = 'export',
  onRowMultiSelectionChange,
  multiSelectedRows,
  getRowId,
  isNested,
  renderSubTable,
  subTableExpandedByDefault = false,
  isVirtual = undefined,
  tableContainerRef = undefined,
  onRowsChange,
  rowsOffset,
  rowsLimit,
  ref,
  emptyState,
}: AutoTableProps<D>) {
  const state = useMemo(() => {
    const s: Partial<TableState> = {
      globalFilter,
    };

    if (multiSelectedRows) {
      s.rowSelection = multiSelectedRows;
    }

    if (sortingState) {
      s.sorting = sortingState;
    }

    if (columnVisibility) {
      s.columnVisibility = columnVisibility;
    }

    if (columnFilters) {
      s.columnFilters = columnFilters;
    }

    return s;
  }, [globalFilter, sortingState, multiSelectedRows, columnVisibility, columnFilters]);

  const table = useReactTable({
    data,
    columns,
    filterFns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: enableSorting ? getSortedRowModel() : undefined,
    enableGlobalFilter: true,
    getColumnCanGlobalFilter: defaultColumnCanGlobalFilter,
    globalFilterFn,
    onSortingChange: onChangeSortingState,
    enableMultiRowSelection,
    onRowSelectionChange: onRowMultiSelectionChange,
    getRowId,
    state,
    onColumnFiltersChange,
  });

  const csvConfig = useMemo(() => {
    const csvOptions = {
      fieldSeparator: ',',
      quoteStrings: true,
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      filename: exportToCSVFilename.replace(/\.csv$/, ''),
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    return mkConfig(csvOptions);
  }, [exportToCSVFilename]);

  const handleExportToCSV = useCallback(() => {
    const { rows } = table.getRowModel();
    const csvData = rows.map((row) =>
      Object.fromEntries(
        row
          .getAllCells()
          .map((cell) => [
            cell.column.columnDef.header &&
            ['string', 'number'].includes(typeof cell.column.columnDef.header)
              ? cell.column.columnDef.header
              : cell.column.columnDef?.meta?.tooltip?.label?.contents ?? '',
            cell.getValue(),
          ]),
      ),
    );

    const csv = generateCsv(csvConfig)(csvData);
    download(csvConfig)(csv);
  }, [csvConfig, table]);

  const filteredRows = getRows(table);

  const getFilteredRows = useCallback(() => filteredRows, [filteredRows]);

  useImperativeHandle(
    ref,
    () => ({
      exportToCSV: handleExportToCSV,
      getFilteredRows,
    }),
    [handleExportToCSV, getFilteredRows],
  );

  useEffect(() => {
    onRowsChange?.(filteredRows);
  }, [filteredRows, onRowsChange]);

  const rows = useMemo(
    () =>
      rowsOffset != null || rowsLimit != null
        ? filteredRows.slice(rowsOffset, rowsLimit ? (rowsOffset ?? 0) + rowsLimit : undefined)
        : filteredRows,
    [filteredRows, rowsOffset, rowsLimit],
  );

  const hasSubTable = renderSubTable;
  const [collapsed, setCollapsed] = useState(subTableExpandedByDefault);
  const [expandedRowsSet, setExpandedRowsSet] = useState(() =>
    subTableExpandedByDefault ? new Set(rows.map((row) => row.id)) : new Set(),
  );
  useEffect(() => {
    if (subTableExpandedByDefault) {
      setExpandedRowsSet(() => new Set(rows.map((row) => row.id)));
    }
  }, [subTableExpandedByDefault, rows]);

  const onToggleRowCollapse = useCallback(
    (row: Row<D>) => {
      const newExpandedSet = new Set([...expandedRowsSet]);
      if (expandedRowsSet.has(row.id)) {
        newExpandedSet.delete(row.id);
      } else {
        newExpandedSet.add(row.id);
      }
      setExpandedRowsSet(newExpandedSet);
    },
    [expandedRowsSet],
  );
  const isRowCollapsed = useCallback(
    (row: Row<D>) => !expandedRowsSet.has(row.id),
    [expandedRowsSet],
  );

  // TODO not functional b/c the ref must be defined outside of this component render. May do later
  // const onScrollBottomReachedInternal = useCallback(
  //   (containerRefElement?: HTMLDivElement | null) => {
  //     if (containerRefElement && onScrollBottomReached) {
  //       const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
  //       // somewhat arbitrary '100' bottom scroll zone activation area
  //       if (scrollHeight - scrollTop - clientHeight < 100) {
  //         onScrollBottomReached(containerRefElement);
  //       }
  //     }
  //   },
  //   [onScrollBottomReached],
  // );

  // const debouncedOnScroll = useDebouncedCallback(() => {
  //   if (tableContainerRef?.current && onScrollBottomReached) {
  //     onScrollBottomReachedInternal(tableContainerRef?.current);
  //   }
  // }, 150);

  if (emptyState && rows.length === 0) {
    return emptyState;
  }

  return (
    <Table isNested={isNested} multiRowSelection={enableMultiRowSelection}>
      <TableHead>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableHeadRow key={headerGroup.id}>
            <TableCellBuffer side="leading" head />
            <TableCellState side="leading" head />
            {hasSubTable && (
              <TableHeadCell
                collapsable
                onClick={() => {
                  setCollapsed(!collapsed);
                  if (collapsed) {
                    setExpandedRowsSet(new Set());
                  } else {
                    setExpandedRowsSet(new Set([...rows.map((row) => row.id)]));
                  }
                }}
                collapsed={collapsed}
              />
            )}
            {headerGroup.headers.map((header) => {
              const meta = header.column.columnDef.meta ?? {};
              return (
                <TableHeadCell
                  key={header.id}
                  sortDirection={header.column.getIsSorted()}
                  onClick={header.column.getToggleSortingHandler()}
                  {...getSizeProps(table, header.column)}
                  accessLevel={meta.accessLevel}
                  isLeading={meta.isLeading}
                  stuck={meta.stuck}
                  tooltip={meta.tooltip}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </TableHeadCell>
              );
            })}
            <TableCellState side="trailing" head />
            <TableCellBuffer side="trailing" head />
          </TableHeadRow>
        ))}
      </TableHead>
      {isVirtual && tableContainerRef ? (
        <VirtualAutoTableBody
          table={table}
          rows={rows}
          tableContainerRef={tableContainerRef}
          isRowDisabled={isRowDisabled}
          isRowSelected={isRowSelected}
          isRowCollapsed={isRowCollapsed}
          onToggleRowCollapse={onToggleRowCollapse}
          isNested={isNested}
          getLinkTo={getLinkTo}
          getDrawerLinkTo={getDrawerLinkTo}
          getEditLinkTo={getEditLinkTo}
          onRowClick={onRowClick}
          multiSelectedRows={multiSelectedRows}
          renderSubTable={renderSubTable}
          actions={actions}
        />
      ) : (
        <AutoTableBody
          table={table}
          rows={rows}
          isRowDisabled={isRowDisabled}
          isRowSelected={isRowSelected}
          isRowCollapsed={isRowCollapsed}
          onToggleRowCollapse={onToggleRowCollapse}
          isNested={isNested}
          getLinkTo={getLinkTo}
          getDrawerLinkTo={getDrawerLinkTo}
          getEditLinkTo={getEditLinkTo}
          onRowClick={onRowClick}
          multiSelectedRows={multiSelectedRows}
          renderSubTable={renderSubTable}
          actions={actions}
        />
      )}
    </Table>
  );
}
